import {
  withMembersArea,
  MA_APP_IDS,
  addApplications as addApplicationsToMa,
} from '@wix/members-area-integration-kit';
import { ChallengesPlatform } from './editor/ChallengesPlatform';
import { PageActionsEvent } from './editor/types/manifest';
import {
  CHALLENGE_PAGE_ID,
  PAYMENT_PAGE_ID,
  THANK_YOU_PAGE_ID,
  CHALLENGES_LIST_PAGE_ID,
} from './editor/app-config';
import { Challenges } from './editor/types/Experiments';

let platform: ChallengesPlatform;

const editorApi = {
  editorReady: async (
    editorSDK,
    appDefinitionId,
    { firstInstall, origin },
    flowAPI,
  ) => {
    const isADI = origin?.type?.toLowerCase().includes('adi');

    platform = new ChallengesPlatform(
      editorSDK,
      appDefinitionId,
      flowAPI,
      isADI,
    );

    const isChallengesInstalled = await platform.isChallengesInstalled();

    if (!isChallengesInstalled) {
      return;
    }

    const showInEditorPageMenu = [
      CHALLENGES_LIST_PAGE_ID,
      CHALLENGE_PAGE_ID,
      PAYMENT_PAGE_ID,
      THANK_YOU_PAGE_ID,
    ];

    const installTransaction = async () => {
      try {
        await addApplicationsToMa([MA_APP_IDS.MY_CHALLENGES]);
      } catch (e) {
        console.error(e);
      }

      await platform.setupPages({
        isFirstInstall: firstInstall,
        showInEditorPageMenu,
      });
    };

    flowAPI.experiments.enabled(Challenges.enableConcurrentMode)
      ? await editorSDK.document.transactions.runAndWaitForApproval(
          appDefinitionId,
          installTransaction,
        )
      : await installTransaction();
  },

  getAppManifest: async () => {
    return platform.getManifest();
  },

  onEvent: async (event) => {
    switch (event.eventType) {
      case PageActionsEvent.REMOVE:
        console.log('DELETE APP');
        return platform.deleteApp(event.eventPayload);
      case PageActionsEvent.APP_ACTION:
        return platform.openDashboard(event?.eventPayload?.actionId);
      default:
        return;
    }
  },

  handleAction: async (event) => {
    const { type, payload } = event;

    console.log('>: handleAction call', event);
    if (type === 'appInstalled') {
      if (!platform?.appDefId) {
        return;
      }

      try {
        switch (payload.appDefinitionId) {
          case platform?.appDefId:
            await platform.installDependencies();
            break;
          default:
        }
      } catch (e) {
        console.error('[Challenges]: error handle action:', e);
        throw e;
      }
    }

    if (type === 'migrate') {
      if (!platform?.appDefId) {
        return;
      }

      await platform.handleMigration(payload);
    }
  },
};

const editorApiWithMA = withMembersArea(editorApi, {
  disableADI: false,
  membersAreaApps: [MA_APP_IDS.NOTIFICATIONS, MA_APP_IDS.MY_CHALLENGES],
});

export const editorReady =
  editorApiWithMA.editorReady as typeof editorApi.editorReady;
export const getAppManifest =
  editorApiWithMA.getAppManifest as typeof editorApi.getAppManifest;
export const onEvent = editorApiWithMA.onEvent as typeof editorApi.onEvent;
export const handleAction = editorApiWithMA.handleAction;
